<template>
  <div>
    <FormFileDragAndDrop class="w-full h-full pt-3 mb-3" :multiple="true" @input="onFilesInputByField">
      <template #default="{ dragActive }">
        <div
          class="flex flex-col items-center justify-center p-6 space-y-2 text-sm bg-gray-300"
          :class="{ 'bg-gray-150': dragActive }"
        >
          <div class="flex flex-row items-center space-x-2 font-semibold">
            {{ $t('Form.Drop your files here') }}
          </div>
        </div>
      </template>
    </FormFileDragAndDrop>
    <div v-for="(link, index) in attachments" :key="index" class="py-1 w-full flex flex-row">
      <!-- eslint-disable -->
      <div
        class="w-5 h-5 rounded-full bg-white overflow-hidden cursor-pointer flex items-center justify-center"
        @click="onDeleteFile(link)"
      >
        <IconClose class="w-4 h-4 text-red-100" />
      </div>
      <!-- eslint-enable -->
      <Attachment
        class="w-full h-full"
        :value="link.attachment"
        :upload-on-mounted="true"
        @loaded="onFileLoaded(link, $event)"
        @load-error="onFileLoadError(link)"
      />
    </div>
  </div>
</template>

<script>
import IconClose from 'devotedcg-ui-components-v2/icons/close.svg?component';

import FormFileDragAndDrop from '@/components/Form/FormFileDragAndDrop.vue';
import Attachment from '@/components/Request/RequestCreate/Attachment.vue';

export default {
  name: 'DragAndDrop',
  components: {
    Attachment,
    FormFileDragAndDrop,
    IconClose,
  },
  props: {
    attachments: {
      type: Array,
      required: true,
    },
  },
  methods: {
    onFilesInputByField({ files }) {
      this.$emit('input', files);
    },

    onDeleteFile(link) {
      this.$emit('delete', link);
    },

    onFileLoaded(local, loaded) {
      this.$emit('loaded', local, loaded);
    },

    onFileLoadError(e) {
      this.$emit('loaderror', e);
    },
  },
};
</script>
